import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  // baseUrl : string = 'https://demo.highwinds.in/webapi/api/';

  baseUrl : string = 'https://dashboard.visionsys.org/webapi/api/';

  // baseUrl : string = 'https://dashboard.netnnetsolutions.com/NetAPI/api/';
  // baseUrl : string = 'https://dashboard.netnnetsolutions.com/test/testapi/api/';


  constructor(private http : HttpClient , private toastr : ToastrService,private router : Router) { 
    
  }


  getApi(url : string){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + localStorage.getItem("userToken")
      }),
    };
    return this.http.get(this.baseUrl + url, httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  optionApi(url : string){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + localStorage.getItem("userToken")
      }),
    };
    return this.http.options(this.baseUrl + url, httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  postApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.baseUrl + url, requestObj, httpOptions)
  }
  postFormDataApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.baseUrl + url, requestObj, httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  putApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.baseUrl + url, requestObj, httpOptions)
  }
  putFormDataApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.baseUrl + url, requestObj, httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
  }


  deleteApi(url : string){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .delete(this.baseUrl + url,  httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
  }


  handleError(error) {
    console.log(error.status)
    // let errorMessage = 'Failed !  url :' + error.url + '  Message : ' + error.error.Message;


  

    if(error.status == 401){
      localStorage.clear();
      this.router.navigate(['/']);
    }
    // window.alert(errorMessage);
    // this.toastr.success('hello')
    // this.toastr.success(errorMessage)
    let errorMessage = error.error.message;
    return throwError(errorMessage);
  }

  
}
