import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { HttpService } from '../../services/http.service';
import { apiUrl } from '../../constants/apiUrl-constants';
import { SharedDataService } from '../../services/shared-data.service';
import { ILoginResponse } from 'src/app/auth/login/login.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  userDetails: ILoginResponse;


  constructor(private router: Router, public navServices: NavService,
    private httpService : HttpService,
    private sharedData : SharedDataService,
    public layout: LayoutService) {

      this.httpService.getApi(apiUrl.CheckToken).subscribe((resdata: any) => {
        if (resdata.success == true) {
  

          
          this.sharedData.userDetails.next(resdata.result);
          if (resdata.result) {
            this.userDetails = resdata.result;
            if (this.userDetails.roleId == 1) {
              this.menuItems = navServices.SUPERADMINMENUITEMS;
            }
            if (this.userDetails.roleId == 2) {
              this.menuItems = navServices.STAFFMENUITEMS;
            }
            if (this.userDetails.roleId == 3) {
              this.menuItems = navServices.HRSTAFFMENUITEMS;
            }
            if (this.userDetails.roleId == 4) {
              this.menuItems = navServices.OPERATORMENUITEMS;
            }
            if (this.userDetails.roleId == 5) {
              this.menuItems = navServices.SUPERWISERMENUITEMS;
            }
            if (this.userDetails.roleId == 6) {
              this.menuItems = navServices.OWNERMENUITEMS;
            }
  
          
            this.navServices.items.next(this.menuItems);
  
          }

          // this.menuItems = navServices.STAFFMENUITEMS
          
          //   this.navServices.items.next(this.menuItems);
        }
        else {
          localStorage.clear();
          this.router.navigate(['/'])
        }
      },
       error =>{
        localStorage.clear();
        this.router.navigate(['/'])
       } 
      )

    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
  

}
